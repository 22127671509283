//
// ProjectCard.tsx
//

import DeleteProject from "@components/Projects/DeleteProject";
import EditProject from "@components/Projects/EditProject";
import SingleProjectInfo from "@components/Projects/SingleProjectInfo";
import { nodeIsWorking, useGetProjectNodes } from "@custom-hooks/Nodes";
import {
  convertEnvCodeToEnvString,
  useGetProjectStorageInfo,
  useGetUserProjects,
} from "@custom-hooks/Projects";
import { Project } from "@data-types/projects-types";
import OpenDialog from "@generic-components/OpenDialog";
import * as Headless from "@headlessui/react";
import DotsHorizontalIcon from "@layouts/svg-icon/dots-horizontal-icon.svg";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@tw-components/ui/dropdown";
import { Link } from "@tw-components/ui/link";
import { NavbarItem } from "@tw-components/ui/navbar";
import { ProgressBar } from "@tw-components/ui/progress-bar";
import { TooltipLabel } from "@tw-components/ui/tooltip";
import clsx from "clsx";
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * ProjectCard Component
 *
 * This component displays a card for a project, showing details like name, description,
 * region, storage, and interactive options for connecting, editing, or deleting the project.
 * If `demo` is provided, it simulates a project display without actual project data.
 *
 * @param {ProjectCardProps} props - Component properties
 * @param {Project | undefined} props.project - The project data, if available
 * @param {string} [props.width="tw-w-[36rem]"] - The width of the card
 * @param {boolean | DemoProps} [props.demo=false] - Demo properties for simulating project data
 * @returns {JSX.Element} The rendered ProjectCard component
 */

export type ProjectCardProps = {
  project?: Project | undefined;
  width?: string;
  demo?:
    | {
        ready: boolean;
        name: string;
        description: string;
        usedStorage: string;
        availableStorage: string;
        storagePercentage: number;
        nodes_count?: number;
      }
    | false;
};

export function ProjectCard({
  project = undefined,
  width = "tw-w-[36rem]",
  demo = false,
}: ProjectCardProps) {
  // Define project data based on `demo` or `project` input
  const finalProject = demo
    ? ({
        id: "",
        name: demo.name,
        description: demo.description,
        nodes_count: 1,
        regionsList: [
          {
            code: "us-west-1",
            description: "US West (N. California)",
          },
        ],
      } as Project)
    : project;

  // Destructure values from the final project data, with fallbacks
  const {
    id = "",
    name = "SQLite Cloud Project",
    description = "A description of the project.",
    nodes_count = 0,
    creating_count = 0,
    env = 0,
    regionsList,
  } = finalProject || {};

  // Convert env code to env string
  const envStatus = convertEnvCodeToEnvString(env);

  // Set the link based on project ID
  const href = id ? `/projects/${id}/nodes` : "#";

  // Fetch nodes data to determine is project is online or offline
  const {
    data: nodes,
    hasData: availableNodes,
    isLoading: loadingNodes,
    mutate: updateProjectNodes,
  } = useGetProjectNodes(id);

  // Initialize `projectWorking` to true, assuming the project is functional until proven otherwise
  let projectWorking = true;

  // Check if nodes data has finished loading and node are available
  if (!loadingNodes && availableNodes) {
    // Loop through each node and check if it is working
    nodes?.forEach((node) => {
      // If any node is not working, set `projectWorking` to false
      if (!nodeIsWorking(node)) {
        projectWorking = false;
      }
    });
  }

  // Use creating counter to know if there are jobs in progress
  const ready = demo ? demo.ready : !creating_count;

  // Fetch storage info for current project id
  const {
    data: storageInfo,
    isLoading: loadingStorageInfo,
    hasData: availableStorageInfo,
    noDatabases: noDatabases,
    mutate: updateProjectStorageInfo,
  } = useGetProjectStorageInfo(id);

  // Poll project updates when creating_count > 0
  useGetUserProjects(creating_count ? 10000 : 0);

  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    // Start polling if creating_count > 0 and polling is not already active
    if (creating_count > 0 && !isPolling) {
      setIsPolling(true);
    }

    // Stop polling if creating_count becomes 0
    if (creating_count === 0 && isPolling) {
      setIsPolling(false);
      // Trigger updates for storage info and nodes
      updateProjectStorageInfo?.();
      updateProjectNodes?.();
    }
  }, [creating_count, isPolling, updateProjectStorageInfo, updateProjectNodes]);

  // Determine storage usage and availability
  const availableStorage = demo
    ? demo.availableStorage
    : availableStorageInfo
      ? storageInfo?.availableStorage
      : "";

  const usedStorage = demo
    ? demo.usedStorage
    : availableStorageInfo
      ? storageInfo?.usedStorage
      : "";
  const storagePercentage = demo
    ? demo.storagePercentage
    : availableStorageInfo
      ? storageInfo?.usedStoragePercentage
      : 0;
  const warning = availableStorageInfo ? storageInfo?.warning : false;

  // Dialog references for project actions
  const deleteProjectDialogRef = useRef<MutableRefObject<any | null>>(null);
  const editProjectDialogRef = useRef<MutableRefObject<any | null>>(null);
  const connectProjectDialogRef = useRef<MutableRefObject<any | null>>(null);

  // Render condition
  const showStorageIndicator =
    demo || (availableStorageInfo && nodes_count > 0);

  // Dropdown menu items with action handlers
  const dropdownItems = [
    {
      children: "Connect",
      disabled: !nodes_count || !projectWorking,
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        connectProjectDialogRef.current?.open();
      },
    },
    {
      children: "Edit details",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        editProjectDialogRef.current?.open();
      },
    },
    {
      children: "Delete project",
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-expect-error OpenDialog not writter for typescript
        deleteProjectDialogRef.current?.open();
      },
    },
  ];
  // Render ProjectCard component
  return (
    <>
      <Link
        href={href}
        className={clsx(
          // Size and interactivity
          width,
          "tw-cursor-pointer",
          // Styling
          "tw-rounded-2xl tw-border  focus:tw-outline-none data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-2",
          "tw-bg-interface-card-light dark:tw-bg-interface-card-dark",
          "tw-dark:tw-border-interface-divider-dark tw-border-interface-divider-light",
          "tw-dark:hover:tw-border-interface-gray-light hover:tw-border-interface-gray-light",
          "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark data-[focus]:tw-outline-brandBlues-brandDark-light",
          // Transition
          "tw-transition-border tw-duration-200 tw-ease-in-out"
        )}
      >
        {/* Content */}
        <div className="tw-relative tw-h-full tw-w-full">
          <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-y-5 tw-overflow-hidden tw-px-5 tw-pb-5 tw-pt-3">
            {/* Header */}
            <div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-dark:tw-text-text-title-dark tw-text-16px-semiBold tw-text-text-title-light">
                  {name}
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3">
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2">
                    {/* Operation status indicator */}
                    <Headless.Button as={Fragment}>
                      {({ hover, active }) => (
                        <span
                          className={clsx(
                            projectWorking
                              ? "tw-dark:tw-bg-semantics-success-dark tw-bg-semantics-success-light"
                              : "tw-dark:tw-bg-semantics-error-dark tw-bg-semantics-error-light",
                            "tw-relative tw-h-3 tw-w-3 tw-rounded-full tw-ring-2 tw-ring-white"
                          )}
                        >
                          {hover && (
                            <TooltipLabel className="tw-absolute -tw-bottom-[6px] tw-right-[0]">
                              {projectWorking ? "Online" : "Offline"}
                            </TooltipLabel>
                          )}
                        </span>
                      )}
                    </Headless.Button>
                    <div
                      className={clsx(
                        "tw-h-fit tw-rounded-2xl tw-px-3 tw-py-1",
                        "tw-dark:tw-text-text-title-dark tw-font-mono tw-text-12px-medium tw-lowercase tw-text-text-title-light",
                        env === 0 && "tw-dark:tw-bg-[#FEE0B4] tw-bg-[#FEE0B4]",
                        env === 1 &&
                          "tw-dark:tw-bg-brandBlues-ice-dark tw-bg-brandBlues-ice-light",
                        env === 2 && "tw-dark:tw-bg-[#C3E9BA] tw-bg-[#C3E9BA]"
                      )}
                    >
                      {envStatus.label}
                    </div>
                  </div>
                  <Dropdown>
                    <DropdownButton as={NavbarItem}>
                      <div className="tw-text-brandBlues-brandDark-light">
                        <DotsHorizontalIcon />
                      </div>
                    </DropdownButton>
                    <DropdownMenu className="min-w-64" anchor="bottom end">
                      {dropdownItems.map((item, i) => (
                        <DropdownItem
                          key={i}
                          onClick={item.onClick}
                          disabled={item.disabled}
                        >
                          <DropdownLabel>{item.children}</DropdownLabel>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div
                className={clsx(
                  "tw-dark:tw-text-text-subTitle-dark tw-mt-1 tw-text-14px-regular tw-text-text-subTitle-light sm:-tw-mt-1"
                )}
              >
                <>
                  {Array.isArray(regionsList) && regionsList?.length > 0 ? (
                    <div className="tw-flex tw-flex-row tw-gap-x-2">
                      {regionsList.map((region) => (
                        <div key={region.code}>{region.code}</div>
                      ))}
                    </div>
                  ) : (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      No region for this project
                    </span>
                  )}
                </>
              </div>
            </div>

            {/* Description */}
            <div className="tw-dark:tw-text-text-subTitle-dark tw-line-clamp-2 tw-max-w-[24.75rem] tw-text-14px-light tw-text-text-subTitle-light">
              {description}
            </div>

            {/* Progress Bar for Storage Usage */}
            <div className="tw-flex-1" />
            <div className="tw-flex tw-max-w-[21rem] tw-flex-col tw-gap-y-2">
              {/* {isAnyJobInProgress || nodes_count ? ( */}
              {projectWorking && (creating_count || nodes_count) ? (
                <ProgressBar
                  value={storagePercentage as number}
                  variant={warning ? "error" : "base"}
                  type={ready ? "determinate" : "indeterminate"}
                />
              ) : (
                <></>
              )}
              <div className="tw-dark:tw-text-text-body-dark tw-text-14px-regular tw-text-text-body-light">
                {ready ? (
                  loadingStorageInfo ? (
                    <div className="tw-h-4 tw-w-20 tw-animate-pulse tw-rounded tw-bg-interface-gray-light dark:tw-bg-interface-gray-dark"></div>
                  ) : !projectWorking ? (
                    <span className=" tw-dark:tw-text-semantics-error-dark tw-text-16px-medium tw-text-semantics-error-light"></span>
                  ) : showStorageIndicator ? (
                    `${usedStorage} / ${availableStorage}`
                  ) : noDatabases ? (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      {nodes_count ? "No databases for this project" : ""}
                    </span>
                  ) : (
                    <span className="tw-dark:tw-text-semantics-error-dark tw-text-semantics-error-light">
                      Error loading storage info
                    </span>
                  )
                ) : creating_count > 0 && nodes_count > 0 ? (
                  "Adding new node ..."
                ) : (
                  "Creating project ..."
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* Connection Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={connectProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        <SingleProjectInfo projectId={id} />
      </OpenDialog>
      {/* Edit Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={editProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        {/* @ts-expect-error OpenDialog not writter for typescript */}
        <EditProject project={project} />
      </OpenDialog>
      {/* Delete Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={deleteProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        {/* @ts-expect-error OpenDialog not writter for typescript */}
        <DeleteProject project={{ name, id }} />
      </OpenDialog>
    </>
  );
}
